.schedule-calendar,
.scheduled-at-picker {
  .react-datepicker {
    font-family: "Montserrat";
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: #e54500 !important;
    border-radius: 8px !important;
  }

  .react-datepicker__header {
    background-color: #fff !important;
  }
}

.schedule-calendar {
  .react-datepicker {
    width: 100% !important;
    border: none !important;
    font-size: 11px !important;

    .react-datepicker__navigation--previous {
      width: 24px !important;
      height: 24px !important;
      left: 201px !important;
      top: 17px !important;
      // padding: 6px 3px 0 0;
      background: #f4f4f4 !important;
      border-radius: 21px !important;
    }

    .react-datepicker__navigation--next {
      width: 24px !important;
      height: 24px !important;
      right: 18px !important;
      top: 17px !important;
      // padding: 6px 0 0 3px;
      background: #f4f4f4 !important;
      border-radius: 21px !important;
    }

    .react-datepicker__navigation-icon::before {
      border-color: #8c8c8c;
    }

    .react-datepicker__header {
      border: none !important;
    }

    .react-datepicker__current-month {
      background: url("/images/calendar.png") no-repeat left !important;
      background-size: 14px 16px !important;
      text-align: left !important;
      margin-top: 12px;
      margin-left: 19px !important;
      padding-left: 22px !important;
    }

    .react-datepicker__month-container {
      float: none !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 25px;
      margin: 6px;
      font-size: 13px;
    }
  }
}

.scheduled-at-picker {
  .react-datepicker__current-month {
    text-align: center !important;
  }

  .react-datepicker__navigation--previous {
    width: 24px !important;
    height: 24px !important;
    top: 9px !important;
    left: 6px !important;
    background: #f4f4f4 !important;
    border-radius: 12px !important;
  }

  .react-datepicker__navigation--next {
    width: 24px !important;
    height: 24px !important;
    top: 9px !important;
    right: 92px !important;
    background: #f4f4f4 !important;
    border-radius: 12px !important;
  }

  .react-datepicker__navigation-icon {
    top: -1.5px !important;
  }

  .react-datepicker__navigation-icon--previous {
    right: -1px !important;
  }

  .react-datepicker__navigation-icon--next {
    left: -1px !important;
  }
}

@media screen and (max-width: 354px) {
  .schedule-calendar,
  .scheduled-at-picker {
    .react-datepicker {
      display: flex;
      flex-direction: column;

      & > .react-datepicker__month-container,
      & > .react-datepicker__time-container,
      & > .react-datepicker__time-list {
        width: 100%;
      }

      & > .react-datepicker__time-container {
        border-left: 0;
      }
    }
  }

  .react-datepicker-popper[data-placement^="top"] {
    transform: translate3d(20px, -319px, 0px) !important;

    & .react-datepicker__triangle {
      display: none !important;
    }
  }

  .scheduled-at-picker .react-datepicker__navigation--previous {
    left: 10px !important;
  }

  .scheduled-at-picker .react-datepicker__navigation--next {
    right: 10px !important;
  }
}
